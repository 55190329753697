<template>
  <div>
    <div class="contain" v-html="processedRichTextContent"></div>
  </div>
</template>

<script>
import { get } from "/utils/request";

export default {
  data() {
    return {
      title: "",
      content:''
    };
  },
  watch: {
    $route(to, from) {
      console.log("to", to);
      console.log("from", from);
      this.title = to.query.text;
      this.getText();
    },
  },
  computed: {
    processedRichTextContent() {
      return this.processImages(this.content);
    }
  },
  mounted() {
    this.title = this.$route.query.text;

    console.log(this.$route.query.text);
    this.getText();
  },
  methods: {
    getText() {
      get("/article", { identifier: this.title }).then((res) => {
        console.log("返回的富文本---", res);
        this.content = res.content;
      });
    },
    processImages(content) {
      // 正则匹配图片路径
      const regex = /<img.*?src=["'](.*?)["']/g;
      let result;
      let newContent = content;
      // 使用正则表达式找到所有图片路径
      while ((result = regex.exec(content)) !== null) {
        const imagePath = result[1];
        // 拼接完整图片路径
        const fullImagePath = this.$imgURL + imagePath;
        // 替换原始图片路径并添加样式
        newContent = newContent.replace(
          `<img src="${imagePath}"`, 
          `<img src="${fullImagePath}" style="max-width:100%;height:auto;"`
        );
      }
      return newContent;
    }
    
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1400px;
  min-height: 442px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}
</style>